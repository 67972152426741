.rights-in{
    border: 1px solid #146C94;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    color: #146C94;
    font-weight: bold;
}

.op-table{
    width: 100%;
    border: 1px solid black;
    border-radius: 3px;
    padding: 5px;
}

.op-table table{
    width: 100%;
}

.op-table tr{
    width: 100%;
}

.ticks{
    padding: 0;
}

.op-table td{
    border-radius: 3px;
    padding: 5px;
    border: 1px solid black;
    text-align: center;
}

