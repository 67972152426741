.padBtn{
    margin: 5px;
}
.reportsHeader{
    display: flex;
    width: 50%; 
    align-items: end;
    margin-left: 10px;
    font-size: 20px;
}

/* .reportsItems{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 1px 0;
} */

.reportTotals{
    font-size: 25px;
    width: 50%;
    margin: 10px;
}

.reportsItem{
    background-color: #F6F1F1;
    text-align: center;
    padding: 5px;
    margin: 0 10px;
    border-radius: 3px;
}

.xls{
    background-color: #F6F1F1;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.xlsIcon{
    width: 25px;
    margin-right: 10px;
}

.reportTableBtns{
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    border: 0;
    font-size: 20px;
    background-color: #146C94;
    color: #FAFAFA;
}

.excels{
    margin-left: 10px;
}

.red{
    color: red;
    font-size: 20px;
    margin: 0;
    align-self: center;
}