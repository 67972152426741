/* ///////////////////////////////////////////////////////////////////////////////// */
/* page and header */
.App{
    /* background-color: rgb(144, 144, 144); */
    min-height: 100vh;
}
.box{
    padding: 10px 20px 0 20px;
}

.header{
    width: 100%; 
    margin-bottom: 5px; 
    display: flex;
    justify-content: space-between;
}

.nav-btns{
    display: flex; 
    align-items: center;
}

.nav-link{
    margin-right: 10px;
}

.links{
    text-decoration: none;
    color: #146C94;
    font-size: 20px;
}
.choosed-link{
    border-bottom: 2px solid #146C94;
}

.center-box{
    text-align: center;
    margin: 5px;
}

.icon-img{
    width: 20px;
    height: auto;
    margin: 5px;
}

/* ///////////////////////////////////////////////////////////////////////////////// */
/* inputs */
.textField{
    padding: 20px;
    border: 0;
    border-radius: 10px;
    background-color: rgb(233, 233, 233);
    color:#146C94;
    margin: 10px;
    outline: none;
    font-size: 15px;
}

.input-areas label{
    color: #146C94;
}

.inputs {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    outline: none;
}


/* ///////////////////////////////////////////////////////////////////////////////// */
/* Buttons */

.logout-btn{
    margin-left: 100px; text-align: center; text-decoration: none;
}

.logout-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

button:hover{
    cursor: pointer;
    opacity: 0.8;
}

.requestBtn{
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    background-color: #146C94;
    color: #FAFAFA;
}

.disBtn{
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    background-color: gray;
    color: rgb(63, 63, 63);
}

.disBtn:hover{
    cursor: default;
    opacity: 1;
}

.closeBtn{
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    background-color: #e7e7e7;
    color: #146C94;
}

.icon-in{
    margin-right: 5px;
    padding: 0;
}

.icon-in img{
    width: 40px;
    margin: 0;
    height: auto;
}

.cross-img {
    transform: rotate(45deg);
}



/* ///////////////////////////////////////////////////////////////////////////////// */
/* Pagination */

.pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    cursor: pointer;
}

.pagination li{
    margin: 5px;
}

.pagination-link-btn{
    background-color: transparent;
    border: 0;
}


/* ///////////////////////////////////////////////////////////////////////////////// */
/* main tables */
.table{
    width: 100%;
}

.table table{
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 5px;
    padding: 20px;
}

.table tr{
    display: flex;
    /* flex-direction: row; */
    /* justify-content: center; */
    align-items: center;
}

.table th{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;    
    width: 100%;
}

.table td{
    width: 100%;
    font-size: 20px;
    text-align: center; 
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
}

.offset-limit{
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* spin modal*/
.load-modal{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #146C94;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}