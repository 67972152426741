.login-window{
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 100vh; 
    /* background-color: black; */
}

.login-box{
    width: 100%;
    max-width: 360px; 
    background-color: #e0e0e0; 
    padding: 100px; 
    border-radius: 10px;
}

.title{
    text-align: center;
    color: #146C94;
}
.log-error{
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-align: center;
}