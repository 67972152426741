.mux{
    margin-bottom: 10px;
}


.whole-mux{
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.mux-container{
    width: 100%;
    max-width: 360px;
}

.mux-items-container{
    display: flex; 
    justify-content: space-between;
    margin: 10px 0;
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
}

.left-container{
    display: flex;  
    align-items: center;
    /* margin-left: 10px; */
}


.constructor-table{
    width: 100%;
    text-align: center;
}
.constructor-table th{
    border: 1px solid black;
    border-radius: 5px;
}

.constructor-table .row-name{
    border: 1px solid black;
    border-radius: 5px;
}

.constructor-table .sub-col{
    display: inline-block;
    width: 33%;
    margin: 0;
}

.constructor-table .checkbox{
    text-align: center;
}

.transp-btn {
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    color: #146C94;
}
