.paymentsHeader{
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}
.yach{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64.4px;
    padding: 5px;
    font-size: 15px; 
}

.payments table{
    padding: 0;
}

.bin-icon{
    width: 30px;
    height: auto;
}
